// src/Components/Admin-Deshboard/pages/AdminAbout.jsx

import React from 'react';

const AdminAbout = () => {
  return (
    <div>
      <h1>About Admin</h1>
      <p>About the admin portal.</p>
    </div>
  );
};

export default AdminAbout;
