import React from 'react';
import './Payment.css';
import paymentImage from '../asets/card.jpg'
import Footer from './Footer';
import Navbar from '../Navbar';
const PaymentCard = ({ bankName, accountNumber, accountHolder, ifscCode, branch, upi }) => (
  <div className="payment-card">
    <h2>{`PAYMENT MODE`}</h2>
    <p><strong>A/C Holder:</strong> {accountHolder}</p>
    <p><strong>A/C No:</strong> {accountNumber}</p>
    <p><strong>Name:</strong> {bankName}</p>
    <p><strong>IFSC Code:</strong> {ifscCode}</p>
    <p><strong>Branch:</strong> {branch}</p>
    <p><strong>UPI:</strong> {upi}</p>
  </div>
);

const Payment = () => {
  return (

  
    <div className="payment-container" >
        <Navbar/>
      {/* Background Image */}
      
      <div className="payment-image" >
        <img src={paymentImage} alt="Payment Details" />
      </div>
   
      {/* Payment Cards Grid */}
      <div className="payment-grid">

        <PaymentCard
        Payment Mode
          bankName="ICICI BANK"
          accountNumber="193705003728"
          accountHolder="GOLDEN EDGE RESEARCH PVT LTD"
          ifscCode=" ICIC0001937"
          branch="K H ROAD BRANCH"
          upi="goldenedgeresearchprivatelimited.ibz@icici"
        />
       
            </div>
            <div className="contact-section">
                <div className="contact-text">
                    <h1>Don't hesitate to contact us any time.</h1>
                </div>
                <div className="contact-button">
                    <button>GET A QUOTE</button>
                </div>
            </div>
            {/* Footer */}
            <Footer />
    </div>
  );
};

export default Payment;
