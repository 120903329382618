import React from "react";
import Navbar from "../Navbar";
import'./Grievances.css'
const Grievances=()=>{
    return(
<>
<Navbar/>

<div className="table-container">
    <h1 className="" style={{textAlign:'center', padding:'5px'}}>Data for the month ending: December 2024</h1>
      <table className="report-table">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Received From</th>
            <th>Pending</th>
            <th>Total Pending</th>
            <th>Resolved</th>
            <th>Pending at the end of the month</th>
            <th>Average Resolution Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>Directly from investors</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr>
            <td>2</td>
            <td>SEBI(SCORES)</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Other Sources(if any)</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr className="grand-total">
            <td colSpan="6">Grand Total</td>
          </tr>
        </tbody>
      </table>
    <h1 className="" style={{textAlign:'center', padding:'5px'}}>Thrend of annual diposal of complaits</h1>
      <table className="report-table">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Month</th>
            <th>Carried from P</th>
            <th>Recived</th>
            <th>Resolved*</th>
            <th>Total Pending</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>January 2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr>
            <td>2</td>
            <td>February 2025</td>
            <td>0 </td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr>
            <td>3</td>
            <td>March  2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr>
            <td>4</td>
            <td>April 2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr>
            <td>5</td>
            <td>May 2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr>
            <td>6</td>
            <td>June 2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>

          <tr>
            <td>7</td>
            <td>July  2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr>
            <td>8</td>
            <td>August 2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
            <tr>
            <td>9</td>
            <td>September 2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>


          <tr>
            <td>10</td>
            <td>October 2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>

          <tr>
            <td>11</td>
            <td>November 2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr>
            <td>12</td>
            <td>December 2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr className="grand-total">
            <td colSpan="6">Grand Total</td>
          </tr>
        </tbody>
        
      </table>
      <h1 className="" style={{textAlign:'center', padding:'5px'}}>Data for the month ending: December 2024</h1>
      <table className="report-table">
        <thead>
          <tr>
            <th>Sr. No.</th>
            <th>Year</th>
            <th>ard from P</th>
            <th>Received during</th>
            <th>Resolved dur</th>
            <th>Pending at the end od the Year</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>2022-2023</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr>
            <td>1</td>
            <td>2023-2024</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          <tr>
            <td>1</td>
            <td>2024-2025</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>0</td>
            <td>NA</td>
          </tr>
          </tbody>
          </table>
    </div>
</>
    );
}
export default Grievances;




