import React from "react";
import privacy from'../asets/pp1.jpg'
import "./PrivacyPolicy.css";
import Footer from "./Footer";
import Navbar from "../Navbar";
const PrivacyPolicy=()=>{
    return(
<>
<Navbar/>
<div className="pimag">
<img src={ privacy} />
</div>
<div className="privacy-text">
<h1>PRIVACY POLICY</h1>
<p>
GOLDEN RESEARCH INDIA PRIVATE LIMITED respects and values the Right to Privacy of each and every individual. We are esteemed by the relationship and by becoming our clients you have a promise from our side that we shall remain loyal to all our clients and non clients whose information resides with us. This Privacy Policy of GOLDEN RESEARCH INDIA PRIVATE LIMITED RESEARCH ANALYST applies to the current clients as well as former clients. You will find the guidelines governing our Privacy Policy below: Your information, whether public or private, will NOT be sold, rented, exchanged, transferred or given to any company or individual for any reason without your consent. Your information will be used SOLELY for providing the services to you for which you have subscribed to us and not for any other purposes. Your information given to us represents your identity with us. If any changes are brought in any of the fields of which you have provided us the information, you shall bring it to our notice by either calling us or sending an email to us.

In addition to the service provided to you, your information (mobile number, E-mail ID etc.) can be brought in use for sending you newsletters, surveys, contest information, or information about any new services of the company which will be for your benefit and while subscribing for our services, you agree that golden Research India Private Limited has the right to do so. By subscribing to our services, you consent to our Privacy Policy and Terms of Use. By filling out the ‘Quick Form’ and the ‘Free Trial Form’ on this website, you agree to provide us your valid mobile number. Also by providing your mobile number, you provide us the consent to call you on the number provided by you and send SMS on your mobile number. You provide your consent for us to use your mobile number even if it is registered on the National ‘Do Not Disturb’ registry.</p>
</div>
<div className="contact-section">
            <div className="contact-text">
                <h1>Don't hesitate to contact us any time.</h1>
            </div>
            <div className="contact-button">
                <button>GET A QUOTE</button>
            </div>
            
        </div>
        <Footer/>
</>
    );
}
export default PrivacyPolicy;