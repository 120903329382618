// src/Components/Admin-Deshboard/pages/AdminServices.jsx

import React from 'react';

const AdminServices = () => {
  return (
    <div>
      hello
    </div>
  );
};

export default AdminServices;
