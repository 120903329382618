import React from 'react';
import './pricing.css'; // Add CSS for styling
import pricingBanner from '../asets/pricing_page.jpg'; // Ensure this is the correct path to your image
import Footer from './Footer';
import Navbar from '../Navbar';

const Pricing = () => {
  const pricingData = [
    {
      packName: "Stock Cash",

      prices: {  Monthly: 10000, Quarterly: 20000, "Half Yearly": 40000, "Annual": 40000 },
    },
    {
      packName: "Stock Futures",
      prices: {  Monthly: 10000, Quarterly: 20000, "Half Yearly": 40000,"Annual": 40000  },
    },
    {
      packName: "Stock Options",
      prices: {  Monthly: 15000, Quarterly: 30000, "Half Yearly": 60000,"Annual": 40000  },
    },
    {
      packName: "Index Future ",
      prices: {  Monthly: 12000, Quarterly: 25000, "Half Yearly": 50000,"Annual": 40000  },
    },
    {
      packName: "Index Future",
      prices: { Monthly: 12000, Quarterly: 25000, "Half Yearly": 50000,"Annual": 40000  },
    },
    {
      packName: "Index Options ",
      prices: { Monthly: 10000, Quarterly: 20000, "Half Yearly": 40000,"Annual": 40000  },
    },
    {
      packName: "Beginner(Single Strategy)",
      prices: {  Monthly: 12000, Quarterly: 25000, "Half Yearly": 50000,"Annual": 40000  },
    },
    {
      packName: "Pro(3  Strategy)",
      prices: {  Monthly: 18000, Quarterly: 36000, "Half Yearly": 72000,"Annual": 40000  },
    },
    {
      packName: "Expert (5 & Above  Strategy)",
      prices: {  Monthly: 12000, Quarterly: 25000, "Half Yearly": 50000,"Annual": 40000  },
    },
    {
      packName: "Personalised Service",
      prices: {  Monthly: 18000, Quarterly: 36000, "Half Yearly": 72000,"Annual": 40000  },
    },
    {
      packName: "Portfolio Health Service",
      prices: {  Monthly: 18000, Quarterly: 36000, "Half Yearly": 72000,"Annual": 40000  },
    },
    {
      packName: "Model Stocks",
      prices: { Monthly: 18000, Quarterly: 36000, "Half Yearly": 72000,"Annual": 40000  },
    },
    {
      packName: "Personalised Portfolio",
      prices: {  Monthly: 18000, Quarterly: 36000, "Half Yearly": 72000,"Annual": 40000  },
    },
  ];

  return (
    
    <div className="pricing-page">
      <Navbar/>
  <div className="pricing-banner">
  <img src={pricingBanner} alt="Pricing Banner" className="pricing-banner-img" />
  <div className="pricing-banner-text">
    Our Pricing
  </div>
</div>
      {/* Pricing Table */}
      <div className="pricing-table">
        <div className="pricing-grid">
          {pricingData.map((pack, index) => (
            <div className="pricing-card" key={index}>
              <h3 className="pack-name">{pack.packName}</h3>
              <table className="pricing-table-details">
                <thead>
                  <tr>
                    <th>Duration</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(pack.prices).map(([duration, price], i) => (
                    <tr key={i}>
                      <td>{duration}</td>
                      <td>{price}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>

      {/* Contact Section */}
      <div className="contact-section">
        <div className="contact-text">
          <h1>Don't hesitate to contact us any time.</h1>
        </div>
        <div className="contact-button">
          <button>GET A QUOTE</button>
        </div>
      </div>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Pricing;
