// src/Components/Admin-Deshboard/pages/AdminContact.jsx

import React from 'react';

const AdminContact = () => {
  return (
    <div>
      <h1>Admin Contact</h1>
      <p>Contact information for admin support.</p>
    </div>
  );
};

export default AdminContact;
