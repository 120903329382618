import React from "react";
import Navbar from "../Navbar";
import img from '../asets/kyc.jpg';
import './Compliances.css';
import Footer from "./Footer";
const Compliances = () => {
  return (
    <>
      <Navbar />
      <div className="mainimg">
        <img src={img} alt="Compliance Image" />
      </div>
      <div className="contact-form-container">
     
      <h2 className="form-title">Add you detail here!!</h2>
      <h5>Note : By Agreeing to the Terme & Conditions,even if you are NDNC registered customer, you agree to receicve call and SMS.!</h5>
      <form>
        <div>
          <input
            type="text"
            id="name"
            className="contact-form-input"
            placeholder=" Name"
          />
        </div>
        <div>
          <input
            type="text"
            id="name"
            className="contact-form-input"
            placeholder="Last Name"
          />
        </div>
        <div>
          <input
            type="Number"
            id="name"
            className="contact-form-input"
            placeholder=" Number"
          />
        </div>
        <div>
          <input
            type="email"
            id="email"
            className="contact-form-input"
            placeholder="Email"
          />
        </div>
        <div>
          <textarea
            id="message"
            className="contact-form-textarea"
            placeholder="Massege"
          ></textarea>
        </div>
        <button type="submit" className="contact-form-button">
          Submit
        </button>
      </form>

    </div>
    <div className="contact-section">
                <div className="contact-text">
                    <h1>Don't hesitate to contact us any time.</h1>
                </div>
                <div className="contact-button">
                    <button>GET A QUOTE</button>
                </div>
            </div>
    <Footer/>
    </>
  );
};

export default Compliances;
