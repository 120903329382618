
import React, { useState } from "react";
import './Home.css';
import image from '../asets/h.jpeg';
import aboutImage from "../asets/fabout1.jpg";
import image1 from"../asets/stock1.jpg"
import image5 from"../asets/stock.jpg"
import image6 from"../asets/stock3.jpg"
import image7 from"../asets/stock4.jpg"
import image8 from"../asets/stock5.jpg"
import image9 from"../asets/skk.jpg"
import Footer from "./Footer";
import Navbar from'../Navbar'
const services = [
  {
    title: "STOCK CASH",
    imgSrc: image1,
    description: "A Premium Service designed for traders who want to trade in high volatility in the Future to capture momentum...",
    details: `A Premium Service designed for traders who want to trade in high volatility in the Future to capture momentum.
      What: Recommendations in Stock Future with Occasional one day Holding.
      Whom: Intraday Trader in Future.
      Ideal for: Ideal for Aggressive clients who want to trade in high volatile market and capture momentum.
      Product Description: Designed for active traders in Future traders in mind, it provides you 30 – 35 recommendations in a month.
      Risk Suitability: Medium.
      Daily: 1-2 Future Recommendations.
      Minimum Investment - INR 3,00,000 & Above.`,
  },
 
  {
    title: "STOCK OPTION",
    imgSrc: image5,
    description: "A Premium Service designed for traders who want to trade in high volatility in the Options to capture momentum...",
    details: `A Premium Service designed for traders who want to trade in high volatility in the Options to capture momentum.
      What: Recommendations in Stock Options with Occasional one day Holding.
      Whom: Intraday Trader in Stock Options.
      Ideal for: Ideal for Aggressive clients who want to trade in high volatile market and capture momentum.`,
  },
  {
    title: "STOCK FUTURE",
    imgSrc: image6,
    description: "A Premium Service designed for traders who want to trade in high volatility in the Options to capture momentum...",
    details: `A Premium Service designed for traders who want to trade in high volatility in the Options to capture momentum.
      What: Recommendations in Stock Options with Occasional one day Holding.
      Whom: Intraday Trader in Stock Options.
      Ideal for: Ideal for Aggressive clients who want to trade in high volatile market and capture momentum.`,
  },
  {
    title: "INDEX FUTURE",
    imgSrc: image7,
    description: "A Premium Service designed for traders who want to trade in high volatility in the Options to capture momentum...",
    details: `A Premium Service designed for traders who want to trade in high volatility in the Options to capture momentum.
      What: Recommendations in Stock Options with Occasional one day Holding.
      Whom: Intraday Trader in Stock Options.
      Ideal for: Ideal for Aggressive clients who want to trade in high volatile market and capture momentum.`,
  },
  {
    title: "INDEX OPTION",
    imgSrc: image8,
    description: "A Premium Service designed for traders who want to trade in high volatility in the Options to capture momentum...",
    details: `A Premium Service designed for traders who want to trade in high volatility in the Options to capture momentum.
      What: Recommendations in Stock Options with Occasional one day Holding.
      Whom: Intraday Trader in Stock Options.
      Ideal for: Ideal for Aggressive clients who want to trade in high volatile market and capture momentum.`,
  },
  {
    title: "BTST/STBT  EQUITY CASE & FUTURE",
    imgSrc: image9,
    description: "A Premium Service designed for traders who want to trade in high volatility in the Options to capture momentum...",
    details: `A Premium Service designed for traders who want to trade in high volatility in the Options to capture momentum.
      What: Recommendations in Stock Options with Occasional one day Holding.
      Whom: Intraday Trader in Stock Options.
      Ideal for: Ideal for Aggressive clients who want to trade in high volatile market and capture momentum.`,
  },
 


];

const Home = () => {
  const [showMoreAbout, setShowMoreAbout] = useState(false);
  const [readMoreIndex, setReadMoreIndex] = useState(null);

  const toggleAboutSection = () => setShowMoreAbout(!showMoreAbout);
  const toggleServiceDetails = (index) => {
    setReadMoreIndex(readMoreIndex === index ? null : index);
  };

  return (
   
      <>
       <Navbar/>
        {/* Main Section */}
        <div className="main">
          <img src={image} alt="Main Banner"/>
        </div>

        {/* About Section */}
        <div className="about-container">
          <div className="left-section">
            <img src={aboutImage} alt="About Us" className="about-image"/>
          </div>
          <div className="right-section">
            <h2>Providing Quality Services to Our Valuable Customers</h2>
            <p>
              Our advisory firm specializes in Commodity Trading, Swing Trading,
              Future Trading, and Option Trading in Commodity & Bullion Markets.
            </p>
            <p>
              We conduct thorough market research and offer a robust platform for
              traders to excel in their endeavors.
            </p>
            {showMoreAbout && (
                <p>
                  <strong>Haiderak Template</strong> continues to expand, thanks to
                  the trust and confidence of our clients.
                </p>
            )}
            <button onClick={toggleAboutSection} className="read-more-btn">
              {showMoreAbout ? "Show Less" : "Read More"}
            </button>
          </div>
        </div>

        {/* Services Section */}
        <h2 style={{textAlign:'center', fontWeight:'800',fontSize:'20px',}}>Services That We Provide</h2>
        <div className="card-container">
          {services.map((service, index) => (
              <div className="carded" key={index}>
                <img className="image" src={service.imgSrc} alt={service.title}/>
                <div className="content">
                  <h3>{service.title}</h3>
                  <p>{readMoreIndex === index ? service.details : service.description}</p>
                  <button className="btn2" onClick={() => toggleServiceDetails(index)}>
                    {readMoreIndex === index ? "Show Less" : "Read More"}
                  </button>
                </div>
              </div>


          ))}
        </div>

        <div>
          {/* Statistics Bar */}
          <div className="statistics-bar">
            <div className="stat-item">
              <i className="icon icon-clients"></i>
              <h2>500+</h2>
              <p>Daily Clients</p>
            </div>
            <div className="stat-item">
              <i className="icon icon-total"></i>
              <h2>1000K+</h2>
              <p>Total Clients</p>
            </div>
            <div className="stat-item">
              <i className="icon icon-assets"></i>
              <h2>20000Cr+</h2>
              <p>Assets Tracked</p>
            </div>
          </div>

          {/* Why Choose Us Section */}
          <div className="why-choose-us">
            <h2>Why Choose Us</h2>
            <div className="features">
              <div className="feature">
                <div>
                  <h3>Over 40 years</h3>
                  <p>of combined corporate experience.</p>
                </div>
                <div className="feature-icon">100%</div>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                   className="bi bi-arrow-return-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
              </svg>
              <div className="feature">
                <div>
                  <h3>Over 40 years</h3>
                  <p>of combined corporate experience.</p>
                </div>
                <div className="feature-icon">100%</div>
              </div>
              <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="currentColor"
                   className="bi bi-arrow-return-right" viewBox="0 0 16 16">
                <path fill-rule="evenodd"
                      d="M1.5 1.5A.5.5 0 0 0 1 2v4.8a2.5 2.5 0 0 0 2.5 2.5h9.793l-3.347 3.346a.5.5 0 0 0 .708.708l4.2-4.2a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 8.3H3.5A1.5 1.5 0 0 1 2 6.8V2a.5.5 0 0 0-.5-.5"/>
              </svg>
              <div className="feature">
                <div>
                  <h3>Over 40 years</h3>
                  <p>of combined corporate experience.</p>
                </div>
                <div className="feature-icon">100%</div>
              </div>
            </div>
          </div>
          <div className="contact-section">
                <div className="contact-text">
                    <h1>Don't hesitate to contact us any time.</h1>
                </div>
                <div className="contact-button">
                    <button>GET A QUOTE</button>
                </div>
            </div>
<Footer/>
        </div>
      </>
  );
};

export default Home;

