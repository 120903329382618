// src/Components/Admin-Deshboard/pages/AdminNavbar.jsx

import React from 'react';

const AdminNavbar = () => {
  return (
    <div>
      <h1>Admin Navbar</h1>
      <p>Navbar for the admin dashboard.</p>
    </div>
  );
};

export default AdminNavbar;
