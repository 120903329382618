// Layout.js

import React, { useState } from "react";
import { Outlet } from 'react-router-dom';
import Sidebar from "../Layout/Sidebar.jsx";
import TopBar from "../Layout/Topbar.jsx";


const Layout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [isHovered, setHovered] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div className="flex">
      <Sidebar 
        isOpen={isSidebarOpen || isHovered} 
        handleMouseEnter={handleMouseEnter} 
        handleMouseLeave={handleMouseLeave} 
      />
      <div className={`flex-grow ${isSidebarOpen ? 'ml-60' : 'ml-[59px]'}`} style={{ overflowY: 'auto' }}>
        <TopBar toggleSidebar={toggleSidebar} />
        <main className="p-4">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

export default Layout;
// import React, { useState } from "react";
// import { Outlet } from "react-router-dom";
// import Sidebar from "../Layout/Sidebar.jsx";
// import TopBar from "../Layout/Topbar.jsx";


// const Layout = () => {
//   const [isSidebarOpen, setSidebarOpen] = useState(false);
//   const [isHovered, setHovered] = useState(false);

//   const toggleSidebar = () => {
//     setSidebarOpen(!isSidebarOpen);
//   };

//   const handleMouseEnter = () => {
//     setHovered(true);
//   };

//   const handleMouseLeave = () => {
//     setHovered(false);
//   };

//   return (
//     <div className="layout-container">
//       <Sidebar
//         isOpen={isSidebarOpen || isHovered}
//         handleMouseEnter={handleMouseEnter}
//         handleMouseLeave={handleMouseLeave}
//       />
//       <div
//         className={`main-content ${isSidebarOpen ? "" : "collapsed"}`}
//       >
//         <div className="top-bar">
//           <TopBar toggleSidebar={toggleSidebar} />
//         </div>
//         <main className="main">
//           <Outlet />
//         </main>
//       </div>
//     </div>
//   );
// };

// export default Layout;
