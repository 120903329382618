// src/Components/Admin-Deshboard/pages/AdminTnC.jsx

import React from 'react';

const AdminTnC = () => {
  return (
    <div>
      <h1>Admin Terms and Conditions</h1>
      <p>Terms and conditions for the admin portal.</p>
    </div>
  );
};

export default AdminTnC;
