import React from "react";
import "./Services.css";
 // Going up two levels to access assets

const ServiceCard = ({ title, description }) => {
    return (
       
        <div className="service-card">
            <div className="service-card-header">
                <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS59BhFOQWRVJEJtLDaSpVLxZYpnjc8UN-c4Q&s"
                    alt="Service Icon"
                    className="service-icon"/>
                    
            </div>
           
            <div className="service-card-body">
                <h3>{title}</h3>
                <p>{description}</p>
                <a href="#" className="read-more">
                    Read More &gt;
                </a>
            </div>
        </div>
    );
};

export default ServiceCard;
