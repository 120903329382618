
import React from "react";
import privacy from'../asets/Refund20banner.webp'

import Footer from "./Footer";
import Navbar from "../Navbar";
import'./REFUNDPOLICY.css'
const REFUNDPOLICY=()=>{
    return(
<>
<div className="">
<Navbar/>
</div>
<div className="privacy">
            <img src={privacy} alt="Privacy Policy" />
        </div>
<div className="privacy-text">
<h1>REFUND POLICY</h1>
<p>
All sales are final. Because GOLDEN RESEARCH INDIA PRIVATE LIMITED offers a free Trial evaluation to ensure that our products and services will meet your needs without the need to purchase, there will be ABSOLUTELY NO REFUNDS and CANCELLATIONS. Before deciding to subscribe to our services, please make sure you have taken our free trial, the evaluation version that we provide. We do not offer refunds on subscriptions that have already been taken. We so believe in our services and support given free trial that, without any exception we a NO REFUND POLICY. If client paid twice for one transaction, the one transaction amount will be refunded via same source within 07 to 10 working days. For this reason, we strongly recommend that before payment, our visitors: Read all information about our products, services and support given to our clients. Evaluate the quality of our services by taking free trial. Read all About Us and our technical Team. Read the Help and Frequently Asked Questions (FAQ) sections. Read our Terms of Use. Read our Privacy Policy..</p>
</div>
<div className="contact-section">
            <div className="contact-text">
                <h1>Don't hesitate to contact us any time.</h1>
            </div>
            <div className="contact-button">
                <button>GET A QUOTE</button>
            </div>
            
        </div>
        <Footer/>
</>
    );
}
export default REFUNDPOLICY;