// src/Components/Admin-Deshboard/pages/AdminPrivacyPolicy.jsx

import React from 'react';

const AdminPrivacyPolicy = () => {
  return (
    <div>
      <h1>Admin Privacy Policy</h1>
      <p>Read the privacy policy for the admin portal.</p>
    </div>
  );
};

export default AdminPrivacyPolicy;
