import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillCaretDown } from 'react-icons/ai';

const TopBar = ({ toggleSidebar }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <div className='flex items-center justify-between sm:px-4 py-4 bg-white shadow'>
      <div className='flex items-center'>
        <button onClick={toggleSidebar} className='text-2xl sm:block hidden'>
          {/* <FaBars /> */}
        </button>
      </div>
      <div className='flex items-center sm:space-x-4 space-x-2'>
        <div className='relative'>
          <div
            className='flex items-center cursor-pointer'
            onClick={toggleDropdown}>
            <span className='sm:text-lg text-sm sm:mr-2'>Admin</span>
            <AiFillCaretDown />
          </div>
          {isDropdownOpen && (
            <div className='absolute right-0 mt-4 w-48 bg-gray-300 border border-gray-200 rounded-lg shadow-md z-10'>
              <Link
                to='/profile'
                className='block px-4 py-2 text-gray-800 hover:bg-gray-500'>
                Profile
              </Link>
              <Link
                to='/settings'
                className='block px-4 py-2 text-gray-800 hover:bg-gray-500'>
                Settings
              </Link>
              <Link
                to='/'
                className='block px-4 py-2 text-red-600 hover:bg-red-100'>
                Log Out
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopBar;

// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { FiMenu } from 'react-icons/fi';
// import { AiFillCaretDown } from 'react-icons/ai';
//  // Import the CSS file

// const TopBar = ({ toggleSidebar }) => {
//   const [isDropdownOpen, setIsDropdownOpen] = useState(false);

//   const toggleDropdown = () => {
//     setIsDropdownOpen(!isDropdownOpen);
//   };

//   return (
//     <div className="top-bar">
//       <div>
//         <button onClick={toggleSidebar} className="menu-button sm-visible">
//           <FiMenu />
//         </button>
//       </div>
//       <div className="admin-section">
//         <div className="relative">
//           <div className="cursor-pointer" onClick={toggleDropdown}>
//             <span className="text-lg sm:mr-2">Admin</span>
//             <AiFillCaretDown />
//           </div>
//           {isDropdownOpen && (
//             <div className="dropdown">
//               <Link to="/" className="dropdown-link">
//                 Log Out
//               </Link>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default TopBar;
