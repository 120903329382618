import React from "react";
import "./Footer.css";
import logo from'../asets/loho.png'

const Footer = () => {
    return (
        
        <footer className="footer">
            <div className="footer-top">
                <div className="footer1">
                    <h3>Have a question? Call us now</h3>
                    <p> +91 9071082611</p>
                </div>
                <div className="footer1">
                    <h3>Telephone Number</h3>
                    <p>007760127197</p>
                </div>
                <div className="footer1">
                    <h3>Need support? Drop us an email</h3>
                    <p>compliance@goldenedgeresearch.com</p>
                </div>
            </div>
            <div className="footer-middle">
                <div className="footer-info">
                    <img src={logo} alt="Logo" className="footer-logo" />
                    <p>goldenedgeresearch.com</p>
                    <p>+91 9071082611</p>
                    <p></p>
                    <p></p>
                    <p>Current Address: No. 311/301/58/1, Singasadra,Begur Hobli, Begur, Bangalore South,, BANGALORE, KARNATAKA, 560068</p>
                </div>
                <div className="footer-links">
                    <h4>Quick Links</h4>
                    <ul>
                        <li>SERVICES</li>
                        <li>PRICING</li>
                        <li>PAYMENT</li>
                        <li>RESEARCH</li>
                        <li>KYC</li>
                    </ul>
                </div>
                <div className="footer-about">
                    <h4>About</h4>
                    <p>
                        Our advisory firm provides Commodity Trading, <br/>
                        Swing Trading, Future Trading, and Option Trading in Commodity & Bullion Market. <br/>It includes research of trading markets and platforms for trading.
                    </p>
                </div>
            </div>
            <div className="footer-bottom">
                <p>© 2025 GOLDEN EDGE RESEARCH PVT LTD All Rights Reserved</p>
                <p>Reg No: INH000016357</p>
            </div>
        </footer>
    );
};

export default Footer;
