import React, { useState } from "react";
import "./About.css"; // Ensure the CSS file is correctly linked
import imagePath from "../asets/fabout1.jpg"; // Confirm the path to the image is accurate
import image from "../asets/a.jpg";
import Footer from "./Footer";
import Navbar from "../Navbar";


const About = () => {
  const [showMore, setShowMore] = useState(false);

  const handleToggleReadMore = () => {
    setShowMore(!showMore);
  };

  return (
    <>
    <Navbar/>
      {/* Main Section */}
      <div className="main">
        <img src={image} alt="Main Banner" />
      </div>

      {/* About Section */}
      <div className="about-container">
        {/* Left Section */}
        <div className="left-section">
          <img
            src={imagePath}
            alt="All dreams are possible"
            className="about-image"
          />
        </div>

        {/* Right Section */}
        <div className="right-section">
          <h2>Providing Quality Services to Our Valuable Customers</h2>
          <p>
            Our advisory firm specializes in Commodity Trading, Swing Trading,
            Future Trading, and Option Trading in Commodity & Bullion Markets.
            We conduct thorough market research and offer a robust platform for
            traders to excel in their endeavors. Our innovative and dedicated
            research team is always prepared to meet the needs of our clients
            with insightful trading tips.
          </p>
          <p>
            We pride ourselves on delivering personalized and exclusive trading
            services, including Intra Day, Positional, BTST, and STBT
            strategies. Our clients consistently express satisfaction with our
            online trading services, which are designed to simplify and enhance
            their trading experience.
          </p>
          {/* Conditionally Render Additional Content */}
          {showMore && (
            <p>
              <strong>Haiderak Template</strong> continues to expand, thanks to
              the trust and confidence of our clients. We cater to a wide range
              of industries, including oil, gas, energy, business services, and
              consumer products, fostering growth and success in these sectors.
            </p>
          )}
          {/* Toggle Button */}
          <button onClick={handleToggleReadMore} className="read-more-btn">
            {showMore ? "Show Less" : "Read More"}
          </button>
        </div>

    </div>
      <div className="all-card">
      <div className="card">
        <div className="circle">	
        Vision</div>
        <div className="text">
        To work for the end to end satisfaction of our client & To provide best of the financial services with personal touch.
        </div>
      </div>

      <div className="card">
        <div className="circle">	
        Mission</div>
        <div className="text">
        To give honest advice to all respected clients,To provide the highest quality report and analysis to the clients & To follow the ethical business practices in the industry.
        </div>
      </div>

      <div className="card">
        <div className="circle">Goal</div>
        <div className="text">
        To become top Leader in advisory services till 2020, our best ethical practices followed by industry, admired by internal and external customers.
        </div>
      </div>
      </div>
      <div className="contact-section">
            <div className="contact-text">
                <h1>Don't hesitate to contact us any time.</h1>
            </div>
            <div className="contact-button">
                <button>GET A QUOTE</button>
            </div>
            
        </div>
      <Footer/>

    </>
  );
};

export default About;  

