
import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import logo from'./asets/stologo.png'



const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
        
        <img style={{ width:'170px', height:'100px'}}
          src={ logo}
          className="logo"
        />
  
        {/* <h1 className="logo-text">GOLDEN <br /></h1> */}
      </div>
      <ul className="nav-links">
        <li><Link to="/">HOME</Link></li>
        <li><Link to="/about">ABOUT</Link></li>
        <li><Link to="/services">SERVICES</Link></li>
        <li><Link to="/pricing">PRICING</Link></li>
        <li><Link to="/payment">PAYMENT</Link></li>
        <li><Link to="/research">CONTACT</Link></li>
        <li><Link to="/privacy-policy"> PRIVACY-POLICY</Link></li>
        <li className="dropdown">
          <span>MORE</span>
          <ul className="dropdown-menu">
            <li><Link to="/Grievances">GRIEVANCES</Link></li>
            <li><Link to="/Refundpolicy">REFUND-POLICY</Link></li>
            <li><Link to="/Compliances ">COMPLIANCES</Link></li>
            <li><Link to="/t&c">T & C</Link></li>
          </ul>
        </li>
      </ul>
  
    </nav>
  );
};

export default Navbar;
