import React from "react";
import './T&C.css'; // Path to your CSS file
import tc1 from '../asets/tc.webp';
import Footer from "./Footer";
import Navbar from "../Navbar";

const TermsAndConditions = () => {
  return (
    <>
    <Navbar/>
      <div className="t&C-image">
        <img src={tc1} alt="Terms and Conditions" />
      </div>
      <div className="privacy-text">
<h1>TERMS & CONDITIONS</h1>
<p>
You agree and understand that the information and material contained in this website implies and constitutes your consent to the terms and conditions mentioned. You also agree that GOLDEN RESEARCH INDIA PRIVATE LIMITED can modify or alter the terms and conditions of the use of this service without any liability. GOLDEN RESEARCH INDIA PRIVATE LIMITED reserves the right to make modifications and alterations to the content of the website. Users are advised to use the data for the purpose of information only and rely on their own judgment while making investment decisions. The investments discussed or recommended may not be suitable for all investors. GOLDEN RESEARCH INDIA PRIVATE LIMITED. does not warranty the timeliness, accuracy or quality of the electronic content. The content of the website cannot be copied, reproduced, republished, uploaded, posted, transmitted or distributed for any non-personal use without obtaining prior permission from GOLDEN RESEARCH INDIA PRIVATE LIMITED. We reserve the right to terminate the accounts of subscribers / customers, who violate the proprietary rights, in addition to necessary legal action. GOLDEN RESEARCH INDIA PRIVATE LIMITED and its owners/affiliates are not liable for damages caused by any performance, failure of performance, error, omission, interruption, deletion, defect, delay in transmission or operations, computer virus, communications line failure, and unauthorized access to the personal accounts. GOLDEN RESEARCH INDIA PRIVATE LIMITED is not responsible for any technical failure or malfunctioning of the software or delays of any kind. We are also not responsible for non-receipt of registration details or e-mails. Users shall bear all responsibility of keeping the password secure. This website is for the exclusive purpose of transactions to be carried out within the territorial jurisdiction of India and all such transactions shall be governed by the laws in India.

Notice is hereby given that Non Resident Indians (NRI’s) and Foreign Nationals accessing this web site and opting to transact thereon shall do so after due verification at their end of their eligibility to do so GOLDEN RESEARCH INDIA PRIVATE LIMITED. undertakes no responsibility for such pre-eligibility of qualification on part of Non-Resident Indians (NRI’s) or Foreign Nationals to transact on this website. GOLDEN RESEARCH INDIA PRIVATE LIMITED along with its directors, employees, associates or other representatives and its Affiliates along with its directors, employees, associates or other representatives shall not be liable for damages or injury arising out of or in connection with the use of the Website or its non-use including non-availability, compensatory, direct, indirect or consequential damages, loss of data, income or profit, loss of or damage to property (including without limitation loss of profits, loss or corruption of data, loss of goodwill, work stoppage, computer failure or malfunction, or interruption of business; under any contract, negligence, strict liability or other theory arising out of or relating in any way to the Website, site-related services, or any products or services and claims of third parties damages or injury caused by any performance, failure of performance, error, omission, interruption, deletion, defect, delay in operation or transmission, computer virus, communications line failure, theft or destruction or unauthorized access to, alteration of, or use of information, whether resulting, in whole or in part, from or relating to any of the services offered or displayed by GOLDEN RESEARCH INDIA PRIVATE LIMITED RESEARCH ANALYST. on the Website . This disclaimer shall be applicable to any person visiting /accessing the Website and/or a Customer entering into an agreement related to availing of the Service offered by GOLDEN RESEARCH INDIA PRIVATE LIMITED.</p>
</div>
<div className="contact-section">
            <div className="contact-text">
                <h1>Don't hesitate to contact us any time.</h1>
            </div>
            <div className="contact-button">
                <button>GET A QUOTE</button>
            </div>
            
        </div>
        <Footer/>
    </>
  );
};

export default TermsAndConditions;
