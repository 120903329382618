import React from "react";
import "./Contact.css"; // Create a CSS file for styles
import backgroundImage from "../asets/cc2.jpg";
import Footer from "./Footer"; // Adjust the path based on your folder structure
import axios from 'axios'; // Make sure to install axios
import  { useState } from 'react';
import Navbar from "../Navbar";


const Contact = () => {

  const [formData, setFormData] = useState({
    email: '',
    name: '',
    address: '',
    message: ''
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };


  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    try {

      const response = await axios.post('http://localhost:8080/v1/contact/create', formData);

      if(response.data.status === 'SUCCESS') {
        alert(response.data.message);
      }else{
        alert(response.data.message);
      }
    } catch (error) {
      alert(error.data.message);
      console.error('Error submitting form:', error);
      // Handle error (e.g., show an error message)
    }
  };



  return (
   
      <div>
         <Navbar/>
    <div
      className="contact-us-container"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "white",
        padding: "50px",
        opacity: 0.8, 
      }}>
      <div className="contact-content">
        <h1>Contact Us</h1>
        <p>
          Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
          ut aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa
          qui officia deserunt mollit anim id est laborum.
        </p>
        <p>Images from Freepik</p>
        <button className="learn-more">LEARN MORE</button>
      </div>
      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Email</label>
            <input type="email"    name="email"
                   value={formData.email}
                   onChange={handleChange} placeholder="Enter a valid email address" />
          </div>
          <div className="form-group">
            <label>Name</label>
            <input type="text"    name="name"
                   value={formData.name}
                   onChange={handleChange} placeholder="Enter your Name" />
          </div>
          <div className="form-group">
            <label>Address</label>
            <textarea  name="address"
                       value={formData.address}
                       onChange={handleChange} placeholder="Enter your address"></textarea>
          </div>
          <div className="form-group">
            <label>Message</label>
            <textarea   name="message"
                        value={formData.message}
                        onChange={handleChange} placeholder="Enter your message"></textarea>
          </div>
          <button type="submit" className="submit-btn">SUBMIT</button>
        </form>
        </div>
    </div>
    <div className="contact-section">
                <div className="contact-text">
                    <h1>Don't hesitate to contact us any time.</h1>
                </div>
                <div className="contact-button">
                    <button>GET A QUOTE</button>
                </div>
            </div>

          <Footer />
      </div>
  );
};

export default Contact;
