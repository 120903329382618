import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FiGrid, FiUser, FiMail } from 'react-icons/fi';

const Sidebar = ({ isOpen, handleMouseEnter, handleMouseLeave }) => {
  const [isMobile, setIsMobile] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    setIsMobile(mediaQuery.matches);

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
      if (mediaQuery.matches) {
        handleMouseLeave();
      }
    };

    mediaQuery.addListener(handleResize);

    return () => {
      mediaQuery.removeListener(handleResize);
    };
  }, [handleMouseLeave]);

  const handleLinkClick = () => {
    if (isMobile) {
      handleMouseLeave();
    }
  };

  const navLinks = [
    { to: '/', icon: <FiGrid />, label: 'AdminDashboard' },
    { to: 'admin-about', icon: <FiUser />,label: 'AdminAbout' },
    { to: 'admin-contact',icon: <FiMail />, label: 'AdminContact' },
    { to: 'admin-payment', /*icon: <FiMail />,*/ label: 'AdminPayment' },
    { to: 'admin-compliance', /*icon: <FiMail />,*/ label: 'AdminCompliance' },
    { to: 'admin-grievance',/* icon: <FiMail />,*/ label: 'AdminGrievance' },
    { to: 'admin-navbar', /*icon: <FiMail />, */label: 'AdminNavbar' },
    { to: 'admin-pricing',/* icon: <FiMail />, */label: 'AdminPricing' },
    { to: 'admin-pricingtable', /*icon: <FiMail />,*/ label: 'AdminPricingTable' },
    { to: 'admin-privacypolicy', /*icon: <FiMail />,*/ label: 'AdminPrivacyPolicy' },
    { to: 'admin-services',/* icon: <FiMail />, */label: 'AdminServices' },
    { to: 'admin-signin',/* icon: <FiMail />,*/ label: 'AdminSignin' },
    { to: 'admin-signup', /*icon: <FiMail />, */label: 'AdminSignup' },
    { to: 'admin-t&c', /*icon: <FiMail />, */label: 'AdminTnC' },
    { to: 'admin-users',/* icon: <FiMail />, */label: 'AdminUsers' },
  ];

  return (
    <div
      className={`h-screen bg-black text-white transition-width duration-300 fixed top-0 left-0 z-10 ${
        isOpen ? 'w-60' : 'w-14'
      } overflow-hidden`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <div className="flex flex-col h-full">
        <div className="p-4 flex-shrink-0 flex items-center">
          {isOpen ? (
            <h1 className="text-2xl font-bold">
              <span className="text-yellow-500">Hi</span>
              <br />
              <span className="text-gray-500 text-md">Jhon David</span>
            </h1>
          ) : (
            <span className="text-yellow-500 text-2xl">JD</span>
          )}
        </div>

        <hr className="w-full border-t border-white" />
        <nav className="flex-grow overflow-y-auto">
          {navLinks.map((link) => (
            <Link
              key={link.to}
              to={link.to}
              onClick={handleLinkClick}
              className={`w-full text-left py-2.5 px-4 rounded transition duration-200 hover:bg-gray-700 flex items-center ${
                location.pathname === link.to ? 'bg-gray-700' : ''
              }`}>
              <div className="flex items-center">
                {link.icon && <div className="text-2xl">{link.icon}</div>}
                <span className={`ml-4 ${!isOpen && 'hidden'}`}>{link.label}</span>
              </div>
            </Link>
          ))}
        </nav>
      </div>
    </div>
  );
};

export default Sidebar;
