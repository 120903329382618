import React, { useState } from "react";
import ServiceCard from "./ServiceCard"; 
import "./Services.css"; 
import Footer from "./Footer";
import servicesImage from '../asets/services-2.jpg';
import Navbar from "../Navbar";


const services = [
    { title: "MCX Pack", description: "A product designed for traders who aim to grab high potential trades from Commodity market." },
    { title: "Prime MCX Pack", description: "A product designed for traders who aim to grab high potential trades from Commodity market." },
    { title: "Equity Cash Pack", description: "A product dedicated for traders who intend to grab potential trades from Intraday Cash market." },
    { title: "Equity Short Term Pack", description: "A product dedicated for traders who intend to grab potential trades with short-term holding." },
    { title: "Index Future Pack", description: "A product dedicated for traders who intend to grab potential trades from Bank Nifty/Nifty." },
    { title: "Derivative Stock Pack", description: "A product dedicated for traders who wish to trade at the derivative segment." },
    { title: "NCDEX Pack", description: "A product for traders focusing on agricultural commodity trades." },
];

const Services = () => {
    const [filter, setFilter] = useState("All");

    // Handle filter change
    const handleFilter = (category) => {
        setFilter(category);
    };

    // Filter services based on the selected category
    const filteredServices =
        filter === "All"
            ? services
            : services.filter((service) => service.title.includes(filter));

    return (
        <div>
            <Navbar/>
            {/* Display the image */}
            <img src={servicesImage} alt="Services Banner" />

            <div className="filter-buttons">
                {["All", "MCX", "Derivative", "Equity", "NCDEX"].map((category) => (
                    <button
                        key={category}
                        onClick={() => handleFilter(category)}
                        className={`filter-button ${filter === category ? "active" : ""}`}
                    >
                        {category}
                    </button>
                ))}
            </div>

            {/* Display Cards */}
            <div className="cards-container">
                {filteredServices.map((service, index) => (
                    <ServiceCard
                        key={index}
                        title={service.title}
                        description={service.description}
                    />
                ))}
            </div>

            {/* Contact Section */}
            <div className="contact-section">
                <div className="contact-text">
                    <h1>Don't hesitate to contact us any time.</h1>
                </div>
                <div className="contact-button">
                    <button>GET A QUOTE</button>
                </div>
            </div>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default Services;
