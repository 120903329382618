import React from 'react';


import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/pages/Home';
import About from '../src/Components/pages/About';
import Services from'./Components/pages/Services '
import Contact from './Components/pages/Contact';
import PrivacyPolicy from './Components/pages/PrivacyPolicy';
import Grievances from './Components/pages/Grievances';
import Refundpolicy from '../src/Components/pages/REFUNDPOLICY';
import Payment from './Components/pages/Payment';
import Pricing from './Components/pages/pricing';
import Footer from './Components/pages/Footer';
import AdminUsers from './Components/Admin-Deshboard/pages/AdminUsers';
import AdminSignin from './Components/Admin-Deshboard/pages/AdminSignin';
import Compliances from './Components/pages/Compliances ';
import Layout from './Components/Admin-Deshboard/views/layout';
import TermsAndConditions from './Components/pages/TermsAndConditions ';
import AdminPrivacyPolicy from './Components/Admin-Deshboard/pages/AdminPrivacyPolicy';
import AdminServices from './Components/Admin-Deshboard/pages/AdminServices';
import AdminTnC from './Components/Admin-Deshboard/pages/AdminTnC';
import AdminNavbar from'./Components/Admin-Deshboard/pages/AdminNavbar'
import AdminContact from'./Components/Admin-Deshboard/pages/AdminContact'
import AdminDashboard from './Components/Admin-Deshboard/pages/AdminDashboard';
import AdminAbout from './Components/Admin-Deshboard/pages/AdminAbout';
// import AdminGrievancePage from './AdminGrievancePage'; 
const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public Routes */}
  
        <Route path="/" element={<Home />} />
        <Route path="/footer" element={<Footer />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/research" element={<Contact />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/grievances" element={<Grievances />} />
        <Route path="/compliances" element={<Compliances />} />
        <Route path="/t&c" element={<TermsAndConditions />} />
        <Route path="/refundpolicy" element={<Refundpolicy />} />
       
        {/* {admin deshboard} */}
        <Route path="/dashboard" element={<Layout />}>
        <Route index element={<AdminDashboard />} />
        <Route path="admin-users" element={<AdminUsers />} />
        <Route path="admin-signin" element={<AdminSignin />} />
        <Route path="admin-privacypolicy" element={<AdminPrivacyPolicy />} />
        <Route path="admin-services" element={<AdminServices />} />
        <Route path="admin-t&c" element={<AdminTnC />} />
        <Route path="admin-about" element={<AdminAbout />} />
        <Route path="admin-contact" element={<AdminContact />} /> 
        <Route path="admin-navbar" element={<AdminNavbar />} />
        

      </Route>
        
      </Routes>
    </Router>
  );
};

export default App;
