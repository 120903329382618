// src/Components/Admin-Deshboard/pages/AdminSignin.jsx

import React from 'react';

const AdminSignin = () => {
  return (
    <div>
      <h1>Admin Sign-In</h1>
      <p>Sign in to access the admin dashboard.</p>
    </div>
  );
};

export default AdminSignin;
